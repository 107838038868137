import { ConquerorModel } from "../models/ConquerorModel";

function addDataRow(
  nr: number,
  name: string,
  place: string,
  date: string
): ConquerorModel {
  return { nr, name, place, date };
}

export const CONQUERORS: ConquerorModel[] = [
  addDataRow(1, "Beata Klimza i Rico", "Warszawa", "2023-10-02"),
  addDataRow(2, "Anna Rybarczyk", "Warszawa", "2023-10-12"),
  addDataRow(3, "Michał Mielniczuk", "Warszawa", "2023-10-13"),
  addDataRow(4, "Stanisław Polit", "Łódź", "2023-12-21"),
  addDataRow(5, "Anna Mielniczuk", "Henryków-Urocze", "2023-10-25"),
  addDataRow(6, "Bartłomiej Makuch", "Żółwin", "2023-12-27"),
  // 7 wolne
  addDataRow(8, "Marcin Banaś", "Strzegom", "2024-01-17"),
  addDataRow(9, "Michał Kieras", "Warszawa", "2024-01-31"),
  addDataRow(10, "Dariusz Kieras", "Warszawa", "2024-01-31"),
  addDataRow(11, "Kamil Niemczak", "Warszawa", "2024-03-05"),
  addDataRow(12, "Andrzej Matysiak", "Warszawa", "2024-03-10"),
  addDataRow(13, "Krzysztof Wrzesiński", "Warszawa", "2024-03-10"),
  addDataRow(14, "Roman Ciepiela", "Staszów", "2024-03-10"),
  addDataRow(15, "Kamil Marczewski", "Warszawa", "2024-03-13"),
  addDataRow(16, "Elżbieta Szuba", "Stanisławów", "2024-03-18"),
  addDataRow(17, "Adam Kosiorek", "Stanisławów", "2024-03-18"),
  addDataRow(18, "rodzina Daruk", "Warszawa", "2024-03-19"),
  addDataRow(19, "Monika Kowalska", "Skarżysko-Kamienna", "2024-03-25"),
  addDataRow(20, "Grzegorz Kowalski", "Skarżysko-Kamienna", "2024-03-25"),
  addDataRow(21, "Magdalena Adamczewska", "Poznań", "2024-03-26"),
  addDataRow(22, "Tamin Said", "Warszawa", "2024-03-26"),
  addDataRow(23, "Mariusz Padziński", "Warszawa", "2024-03-29"),
  addDataRow(24, "Ewelina Gwiazdowska", "Ostrołęka", "2024-03-30"),
  addDataRow(25, "Tomasz Gołyński", "Warszawa", "2024-04-02"),
  addDataRow(26, "Beata Kupidura", "Warszawa", "2024-04-02"),
  addDataRow(27, "Przemysław Kupidura", "Warszawa", "2024-04-02"),
  addDataRow(28, "Beata Gołacka-Piotrowska", "Warszawa", "2024-04-02"),
  addDataRow(29, "Tomasz Deptuła", "Warszawa", "2024-04-02"),
  addDataRow(30, "Anna Mach", "Warszawa", "2024-04-10"),
  addDataRow(31, "Aleksandra Obermiller", "Warszawa", "2024-04-10"),
  addDataRow(32, "Urszula Klimska", "Warszawa", "2024-04-10"),
  addDataRow(33, "Justyna Sroczyńska", "Piaseczno", "2024-04-10"),
  addDataRow(34, "Marcin Sroczyński", "Piaseczno", "2024-04-10"),
  addDataRow(35, "Wiktoria Radkowska", "Warszawa", "2024-04-15"),
  addDataRow(36, "Robert Kędzierski", "Warszawa", "2024-04-15"),
  addDataRow(37, "Anna Hajduk", "Owczarnia", "2024-04-15"),
  addDataRow(38, "Łukasz Wleklik", "Warszawa", "2024-04-15"),
  addDataRow(39, "Tomasz Wiącek", "Legionowo", "2024-04-21"),
  addDataRow(40, "Magdalena Zając", "Marki", "2024-04-28"),
  addDataRow(41, "Marcin Zając", "Marki", "2024-04-28"),
  addDataRow(42, "Marta Czyżewska", "Warszawa", "2024-04-28"),
  addDataRow(43, "Wiesława Twardowska", "Legionowo", "2024-04-28"),
  addDataRow(44, "Emil Śmierzyński", "Warszawa", "2024-04-29"),
  addDataRow(45, "Karol Kowalski", "Warszawa", "2024-04-29"),
  addDataRow(46, "Mateusz Ostrowski", "Warszawa", "2024-04-29"),
  addDataRow(47, "Łukasz Nastański", "Warszawa", "2024-04-29"),
  addDataRow(48, "Tomasz Kalisz", "Warszawa", "2024-04-29"),
  addDataRow(49, "Rafał Tłuchowski", "Warszawa", "2024-04-29"),
  addDataRow(50, "Anna Rynkiewicz", "Przerośl", "2024-04-29"),
  addDataRow(51, "Piotr _", "Warszawa", "2024-04-29"),
  addDataRow(52, "Maria Talko", "Warszawa", "2024-05-01"),
  addDataRow(53, "Łukasz Szmit", "Warszawa", "2024-05-01"),
  addDataRow(54, "Tomasz Niedźwiedź", "Warszawa", "2024-05-02"),
  addDataRow(55, "Agnieszka Tyszka-Niedźwiedź", "Warszawa", "2024-05-02"),
  addDataRow(56, "Natasza Niedźwiedź", "Warszawa", "2024-05-02"),
  addDataRow(57, "Franciszek Niedźwiedź", "Warszawa", "2024-05-02"),
  addDataRow(58, "Adrian Szymański", "Ząbki / Warszawa", "2024-05-04"),
  addDataRow(59, "Dorota Prus", "Warszawa", "2024-05-05"),
  addDataRow(60, "Monika Piotrowska", "Milanówek", "2024-05-05"),
  addDataRow(61, "Piotr Juszczyk", "Warszawa", "2024-05-05"),
  addDataRow(62, "Klara Demczuk", "Warszawa", "2024-05-06"),
  addDataRow(63, "Karina Demczuk", "Warszawa", "2024-05-06"),
  addDataRow(64, "Kornel Demczuk", "Warszawa", "2024-05-06"),
  addDataRow(65, "Aleksander Gińko", "Warszawa", "2024-05-06"),
  addDataRow(66, "Marcin Nowak", "Poznań", "2024-05-09"),
  addDataRow(67, "Alina Łuć", "Warszawa", "2024-05-10"),
  addDataRow(68, "Krzysztof _", "Warszawa", "2024-05-09"),
  addDataRow(69, "Ewelina Gomuła", "Warszawa", "2024-05-12"),
  addDataRow(70, "Bartłomiej Sudakowski", "Warszawa", "2024-05-19"),
  addDataRow(71, "Kaja Grudnicka", "Warszawa", "2024-05-19"),
  addDataRow(72, "Alicja Bis", "Warszawa", "2024-05-21"),
  addDataRow(73, "Agata Tomala", "Warszawa", "2024-05-23"),
  addDataRow(74, "Krzysztof Dorosz", "Warszawa", "2024-05-16"),
  addDataRow(75, "Piotr Osumek", "Radomsko", "2024-05-16"),
  addDataRow(76, "Łukasz Nowak", "Bielsko-Biała", "2024-05-16"),
  addDataRow(77, "Karolina Ciołek", "Warszawa", "2024-05-26"),
  addDataRow(78, "ZORI", "Warszawa", "2024-05-26"),
  addDataRow(79, "Zbigniew Ciołek", "Warszawa", "2024-05-26"),
  addDataRow(80, "Zhanna Makarava", "Warszawa", "2024-05-27"),
  addDataRow(81, "Ireneusz Lubański", "Warszawa", "2024-05-27"),
  addDataRow(82, "Łukasz Ryszard Rubin-Szymański", "Łomianki", "2024-05-21"),
  addDataRow(
    83,
    "Michalina Małgorzata Rubin-Szymańska",
    "Łomianki",
    "2024-05-21"
  ),
  addDataRow(
    84,
    "Krzysztof Amadeusz Rubin-Szymański",
    "Łomianki",
    "2024-05-21"
  ),
  addDataRow(85, "Jacek Boborycki", "Warszawa", "2024-06-01"),
  addDataRow(86, "Jędrzej Szum", "Warszawa", "2024-06-08"),
  addDataRow(87, "Katarzyna Pacek", "Warszawa", "2024-06-08"),
  addDataRow(88, "Ewa Kalinowska", "Warszawa", "2024-06-12"),
  addDataRow(89, "Dariusz Kalinowski", "Warszawa", "2024-06-12"),
  addDataRow(90, "Aleksander Beker", "Warszawa", "2024-06-15"),
  addDataRow(91, "Iwona Pieronek", "Piastów", "2024-06-20"),
  addDataRow(92, "Krystian Gaik", "Warszawa", "2024-06-20"),
  addDataRow(93, "Marcelina Andrzejewska", "Warszawa", "2024-07-01"),
  addDataRow(94, "Sara Birecka", "Warszawa", "2024-07-01"),
  addDataRow(95, "Marta Malińska", "Warszawa", "2024-07-01"),
  addDataRow(96, "Hubert Janczak", "Konstancin-Jeziorna", "2024-07-08"),
  addDataRow(97, "Maciej Lewkowicz", "Raszyn", "2024-07-08"),
  addDataRow(98, "Weronika Gadzicka", "Warszawa", "2024-07-08"),
  addDataRow(99, "Krzysztof Mędza", "Warszawa", "2024-07-14"),
  addDataRow(100, "Małgorzata Mędza", "Warszawa", "2024-07-14"),
  addDataRow(101, "Kamil Kamiński", "Warszawa", "2024-07-13"),
  addDataRow(102, "Marcel Kamiński", "Warszawa", "2024-07-13"),
  addDataRow(103, "Jakub Ciostek", "Legionowo", "2024-07-16"),
  addDataRow(104, "Adrianna Nowakowska", "Warszawa", "2024-07-22"),
  addDataRow(105, "Krzysztof Nowakowski", "Warszawa", "2024-07-22"),
  addDataRow(106, "Edward Chojnacki", "Warszawa", "2024-07-28"),
  addDataRow(107, "Jan Chojnacki", "Warszawa", "2024-07-28"),
  addDataRow(108, "Maja Chojnacka", "Warszawa", "2024-07-28"),
  addDataRow(109, "Marina Sawicka", "Grodno", "2024-07-21"),
  addDataRow(110, "Julia Świątkowska", "Warszawa", "2024-07-27"),
  addDataRow(111, "Tomasz Niedziałkowski", "Warszawa", "2024-07-27"),
  addDataRow(112, "Arkadiusz Kulik", "Warszawa", "2024-07-31"),
  addDataRow(113, "Michał Zbrowski", "Pruszków", "2024-07-31"),
  addDataRow(114, "Adam Makowiecki", "Warszawa", "2024-08-02"),
  addDataRow(115, "Kamila Makowiecka", "Warszawa", "2024-08-02"),
  addDataRow(116, "Przemysław Stachura", "Marki", "2024-08-04"),
  addDataRow(117, "Oliwier Stachura", "Marki", "2024-08-04"),
  addDataRow(118, "Basia Stachura", "Marki", "2024-08-04"),
  addDataRow(119, "Aleksander Gieniuk", "Łomianki Dolne", "2024-08-06"),
  addDataRow(120, "Ryszard Gieniuk", "Łomianki Dolne", "2024-08-06"),
  addDataRow(121, "Anna Rewińska", "Warszawa", "2024-08-05"),
  addDataRow(122, "Karolina Rewińska", "Warszawa", "2024-08-05"),
  addDataRow(123, "Mirosław Szklarz", "Częstochowa", "2024-08-05"),
  addDataRow(124, "Marta Miniewicz", "Kalisz", "2024-08-05"),
  addDataRow(125, "Krzysztof Szklarz", "Częstochowa", "2024-08-05"),
  addDataRow(126, "Hanna Szmitkowska", "Warszawa", "2024-08-07"),
  addDataRow(127, "Paweł Listkowski", "Warszawa", "2024-08-07"),
  addDataRow(128, "Bartosz Łukaszewski", "Warszawa", "2024-08-07"),
  addDataRow(129, "Maciej Tworek", "Warszawa", "2024-08-07"),
  addDataRow(130, "Marta Stogłów", "Warszawa", "2024-08-11"),
  addDataRow(131, "Maja Bury", "Warszawa", "2024-08-07"),
  addDataRow(132, "Przemysław Pikuła", "Warszawa", "2024-08-11"),
  addDataRow(133, "Robert Gadomski", "Warszawa", "2024-08-14"),
  addDataRow(
    134,
    "Włodzimierz Matysiak",
    "Konarzewo k. Poznania",
    "2024-08-17"
  ),
  addDataRow(135, "Iwona Jankowska", "Zduny k. Krotoszyna", "2024-08-17"),
  addDataRow(136, "Michał Żurawski", "Konstancin-Jeziorna", "2024-08-20"),
  addDataRow(137, "Justyna Grochowska", "Warszawa", "2024-08-22"),
  addDataRow(138, "anonimowy Zdobywca", "", "2024-08-23"),
  addDataRow(139, "anonimowy Zdobywca", "", "2024-08-23"),
  addDataRow(140, "Bartosz Stobiecki", "Warszawa", "2024-08-25"),
  addDataRow(141, "Łukasz Kamiński", "Warszawa", "2024-08-24"),
  addDataRow(
    142,
    "Krzysztof Gruszka, Bianka Murawska",
    "Warszawa",
    "2024-08-24"
  ),
  addDataRow(143, "Mateusz Marjański", "Warszawa", "2024-08-24"),
  addDataRow(144, "Aneta Witczak", "Warszawa/Elbląg", "2024-08-24"),
  addDataRow(145, "Klaudia Słowiak", "Warszawa", "2024-08-27"),
  addDataRow(146, "Dominik Słowiak", "Warszawa", "2024-08-27"),
  addDataRow(147, "Mateusz Głuchowski", "Górki/Warszawa", "2024-08-24"),
  addDataRow(148, "Gustaw Goworek", "Stara Wieś", "2024-08-31"),
  addDataRow(149, "Gaweł Goworek", "Stara Wieś", "2024-08-31"),
  addDataRow(150, "Martyna Pawluczuk", "Warszawa", "2024-09-02"),
  addDataRow(151, "Adam Godek", "Warszawa", "2024-09-02"),
  addDataRow(152, "Paweł Downarowicz", "Warszawa", "2024-09-03"),
  addDataRow(153, "Miłosz Majewski", "Nowy Dwór Mazowiecki", "2024-09-08"),
  addDataRow(154, "Borys Grzelka", "Łomianki", "2024-09-08"),
  addDataRow(155, "Mikołaj Lejkowski", "Warszawa", "2024-09-08"),
  addDataRow(156, "Jan Wisłocki", "Warszawa", "2024-09-08"),
  addDataRow(157, "Bartłomiej Pożeracz", "Warszawa", "2024-09-08"),
  addDataRow(158, "Edward Rasiewicz", "Warszawa", "2024-09-08"),
  addDataRow(159, "Wojciech Ostaszewski", "Warszawa", "2024-09-08"),
  addDataRow(160, "Ignacy Solski", "Warszawa", "2024-09-08"),
  addDataRow(161, "Gabriel Kotarba", "Warszawa", "2024-09-08"),
  addDataRow(162, "Nathan Klimkiewicz", "Warszawa", "2024-09-08"),
  addDataRow(163, "Franciszek Wawiórowski", "Warszawa", "2024-09-08"),
  addDataRow(164, "Tomasz Osiej", "Lublin", "2024-09-01"),
  addDataRow(165, "Zuzanna Osiej", "Lublin", "2024-09-01"),
  addDataRow(166, "Jan Osiej", "Lublin", "2024-09-01"),
  addDataRow(167, "Andrzej Basiaga", "Kamienica", "2024-09-10"),
  addDataRow(168, "Magdalena Ostrowska", "Elbląg", "2024-10-02"),
  addDataRow(169, "Krzysztof Ostrowski", "Elbląg", "2024-10-02"),
  addDataRow(170, "Michał Żamojtuk", "Białystok", "2024-10-04"),
  addDataRow(171, "Aleksandra Boszczak", "Warszawa", "2024-10-05"),
  addDataRow(172, "Kacper Boszczak", "Warszawa", "2024-10-05"),
  addDataRow(173, "Filip Boszczak", "Warszawa", "2024-10-05"),
  addDataRow(174, "Natalia Filipowicz", "Warszawa", "2024-10-08"),
];
